import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import './authentication.scss';
import { Paragraph, PreformattedText, NumberedList, ListItem, UnorderedList } from '@entur/typography';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const BannerAlertBox = makeShortcode("BannerAlertBox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "authentication"
    }}>{`Authentication`}</h1>
    <h2 {...{
      "id": "et-client-name-header"
    }}>{`ET-Client-Name Header`}</h2>
    <p>{`Most APIs are open under `}<a parentName="p" {...{
        "href": "https://data.norge.no/nlod/en"
      }}>{`Norwegian Licence for Open Government Data (NLOD)`}</a>{`, however, it is required that all consumers identify themselves by using the header `}<inlineCode parentName="p">{`ET-Client-Name`}</inlineCode>{`.`}</p>
    <p>{`Entur will deploy strict rate-limiting policies on API-consumers who do not identify with a header and reserves the right to block unidentified consumers. The structure of ET-Client-Name should be: `}<inlineCode parentName="p">{`"<company>-<application>"`}</inlineCode>{`.`}</p>
    <p>{`Header examples:`}</p>
    <div className="authentication__textbox-wrapper">
    <UnorderedList className="authentication__textbox-content" mdxType="UnorderedList">
        <ListItem mdxType="ListItem">"brakar-journeyplanner"</ListItem>
        <ListItem mdxType="ListItem">"fosen_utvikling-infoplakat"</ListItem>
        <ListItem mdxType="ListItem">"nor_way_bussekspress-reiseplanlegger"</ListItem>
    </UnorderedList>
    </div>
    <h2 {...{
      "id": "credentials"
    }}>{`Credentials`}</h2>
    <p>{`The use of Entur's privileged endpoints requires authentication. Entur's APIs use OAuth2 authentication, with the Client Credentials Grant (`}<a parentName="p" {...{
        "href": "https://oauth.net/2/grant-types/client-credentials/"
      }}>{`https://oauth.net/2/grant-types/client-credentials/`}</a>{`).`}</p>
    <p>{`This works by exchanging a client ID and a client secret for an access token which is included in the header for API requests.`}</p>
    <BannerAlertBox title="Confidentiality" variant="warning" mdxType="BannerAlertBox">
    All Client Credentials (client id + client secret) and access tokens issued
    by Entur are intended for Server-to-Server (S2S) communication only! Under
    no circumstance must they be exposed to users (see below).
    <br />
    Communication must always be over HTTPS.
    </BannerAlertBox>
    <h3 {...{
      "id": "authentication-flow"
    }}>{`Authentication Flow`}</h3>
    <p>{`Entur uses OAuth2 as the authentication protocol, and the Client Credentials grant as authentication flow for non-interactive authentication. Client Credentials grant works by exchanging a client ID and a client secret provided by Entur for an access token from the authentication service.`}</p>
    <p>{`The access token is a JWT (which will be covered in detail later) and is included in the HTTP Authentication header by the application sending requests to Entur's APIs. The token is validated on every request, and an invalid token will result in a rejected request.`}</p>
    <p>{`The following illustration is a high level description of the authentication flow:`}</p>
    <p><img alt="authentication flow chart" src={require("./authentication-flow.svg")} /></p>
    <p>{`Illustration 1.1: Authentication flow`}</p>
    <ol>
      <li parentName="ol">{`The application sends an authentication request with a "client_id" and "client_secret" to the Authentication Service (AS).`}</li>
      <li parentName="ol">{`The Authentication Service (AS) validates the credentials and returns an access token.`}</li>
      <li parentName="ol">{`The application includes the access token in the request header to the Entur APIs.`}</li>
      <li parentName="ol">{`The APIs validates the access token, executes the action and/or returns the requested information.`}</li>
    </ol>
    <h3 {...{
      "id": "obtaining-a-token"
    }}>{`Obtaining a Token`}</h3>
    <p>{`To obtain a token you need to send an authentication request to the authentication endpoint: `}<inlineCode parentName="p">{`https://<AUTHENTICATION SERVICE HOST>/oauth/token`}</inlineCode>{`. In addition to a valid client ID and client secret, the request must include a valid `}<inlineCode parentName="p">{`Audience`}</inlineCode>{` value.`}</p>
    <p>{`Authentication server and audience available for Partners:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Environment`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Authentication Service Host`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Audience`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Dev`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`partner.dev.entur.org`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`api.dev.entur.io`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Staging`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`partner.staging.entur.org`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`api.staging.entur.io`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Production`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`partner.entur.org`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`api.entur.io`}</td>
        </tr>
      </tbody>
    </table>
    <p><em parentName="p">{`Dev`}</em>{` and `}<em parentName="p">{`Staging`}</em>{` consist of data which is used for testing.`}</p>
    <p>{`The following example shows how an access token can be obtained by using the provided information and the `}<inlineCode parentName="p">{`curl`}</inlineCode>{` command:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl --request POST \\
  --url https://partner.entur.org/oauth/token \\
  --header 'content-type: application/json' \\
  --data '{
    "client_id":" Q2xpZW50X0lE ",
    "client_secret": 🤫,
    "audience":"https://api.entur.io",
    "grant_type":"client_credentials"
  }
`}</code></pre>
    <p>{`There are several libraries available for authenticating your application, including our own `}<a parentName="p" {...{
        "href": "https://github.com/entur/jwt-resource-server/tree/master/jwt-client"
      }}>{`Enturs JWT-client`}</a>{`. We highly recommend using a library for your intended language and framework instead of authenticating manually.`}</p>
    <h3 {...{
      "id": "the-access-token"
    }}>{`The Access Token`}</h3>
    <p>{`The access tokens used by Entur are JWTs (`}<a parentName="p" {...{
        "href": "https://jwt.io/introduction/"
      }}>{`https://jwt.io/introduction/`}</a>{`), which are Base64-encoded, signed self-contained documents consisting of three parts: A header, payload and cryptocraphic signature to verify the validity and integrity of the token. The access token contains all necessary information to authenticate your application for Entur's APIs.`}</p>
    <p>{`For more information, check out `}<a parentName="p" {...{
        "href": "https://auth0.com/docs/tokens/reference/jwt/jwt-structure"
      }}>{`https://auth0.com/docs/tokens/reference/jwt/jwt-structure`}</a>{`.`}</p>
    <h4 {...{
      "id": "caching"
    }}>{`Caching`}</h4>
    <p>{`Access tokens are stateless documents, meaning they can be used for multiple requests and in parallel if needed. It's neither necessary nor efficient to re-authenticate for every API request. `}<strong parentName="p">{`You're required to cache the access token`}</strong>{`, in memory or through a secure storage mechanism to avoid needless re-authentication.`}</p>
    <p>{`Failure to do so might lead to authentication requests being throttled or worst case having your client blocked/disabled.`}</p>
    <h4 {...{
      "id": "using-access-tokens-in-requests"
    }}>{`Using Access Tokens in Requests`}</h4>
    <p>{`After obtaining the access token (JWT), add it to the `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` HTTP header in subsequent requests to the APIs:`}</p>
    <pre><code parentName="pre" {...{}}>{`Authorization: Bearer <JWT token>​
`}</code></pre>
    <p>{`For example`}</p>
    <PreformattedText className="authentication__preformatted-text authentication__preformatted-text--break-word" mdxType="PreformattedText">
    Authorization: Bearer
    eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c
    </PreformattedText>
    <p>{`Entur's APIs then validate the access token signature and grant the corresponding access.`}</p>
    <h4 {...{
      "id": "expiry-and-re-authentication"
    }}>{`Expiry and re-authentication`}</h4>
    <p>{`Access tokens have longevity but will expire after a certain amount of time. If the token has expired, API calls will return HTTP status 401. Keep track of the remaining time using the `}<inlineCode parentName="p">{`expires_in`}</inlineCode>{` sibling field returned by the client credentials call, or by checking the `}<inlineCode parentName="p">{`exp`}</inlineCode>{` claim/value in the payload section of the JWT.`}</p>
    <p>{`We recommend that some time (a few (10) seconds, ideally the time it takes to make the call) is left on the token when invoking requests. `}<strong parentName="p">{`Your implementation should NOT wait for 401 responses before refreshing the token`}</strong>{`, as this will spam our logs (and potentially make a bit of thread spagetti in your backend).`}</p>
    <h3 {...{
      "id": "http-error-messages"
    }}>{`HTTP Error Messages`}</h3>
    <p>{`These error messages are from the APIs.`}</p>
    <UnorderedList className="authentication__textbox-unorderedlist" mdxType="UnorderedList">
    <ListItem className="authentication__textbox-listitem" mdxType="ListItem">
        301: Moved Permanently - The HTTP server was been moved permanently to a
        HTTPS server. This error is used for permanent URL redirection.
    </ListItem>
    <ListItem className="authentication__textbox-listitem" mdxType="ListItem">
        302: Redirect - Attempts to connect to the Entur APIs with HTTP will end
        in a redirection. Make sure your server is HTTPS.
    </ListItem>
    <ListItem className="authentication__textbox-listitem" mdxType="ListItem">
        401: Unauthorized - The request is lacking valid authentication. This
        may indicate that the “client_secret” has been changed or is not
        correct.
    </ListItem>
    <ListItem className="authentication__textbox-listitem" mdxType="ListItem">
        403: Forbidden - If the scope claim does not include a request your
        application is trying to acces you will get this error. This may
        indicate that your application is wrongly configured or that you do not
        have the permissions needed for making changes to the specific endpoint.
        Ref.
        https://auth0.com/docs/api-auth/tutorials/verify-access-token#validate-t...
        (“How can I check the permissions?”).
    </ListItem>
    <ListItem className="authentication__textbox-listitem" mdxType="ListItem">
        405: Method not allowed - This might mean that your BFF is trying to
        connect to the server via HTTP, which the Entur API does not support.
    </ListItem>
    <ListItem className="authentication__textbox-listitem" mdxType="ListItem">
        408: Session timeout - The access token has expired while the session
        was ongoing. Reauthenticate the session and remember to check when the
        access token expires.
    </ListItem>
    </UnorderedList>
    <h3 {...{
      "id": "troubleshooting"
    }}>{`Troubleshooting`}</h3>
    <p>{`These errors are coming from the authentication service.`}</p>
    <p><strong parentName="p">{`“Invalid grant”`}</strong>{` - This is a generic error message and may mean one of the following:`}</p>
    <ul>
      <li parentName="ul">{`Wrong username and/or password`}</li>
      <li parentName="ul">{`Username is from an invalid domain`}</li>
      <li parentName="ul">{`Endpoint does not support “Resource Owner Password Credentials Grant”. Validate that you are using the “client_id” correctly.`}</li>
    </ul>
    <p><strong parentName="p">{`“Unauthorized client”`}</strong>{` - See “401: Unathorized”:`}</p>
    <ul>
      <li parentName="ul">{`“client_secret” might have been changed. Check if it is the same.`}</li>
    </ul>
    <h3 {...{
      "id": "reference-list"
    }}>{`Reference List`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://auth0.com/docs/api-auth/tutorials/verify-access-token#validate-the-claims"
        }}>{`https://auth0.com/docs/api-auth/tutorials/verify-access-token#validate-the-claims`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://auth0.com/docs/tokens/use-access-tokens"
        }}>{`https://auth0.com/docs/tokens/use-access-tokens`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://auth0.com/docs/security/store-tokens"
        }}>{`https://auth0.com/docs/security/store-tokens`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tools.ietf.org/html/draft-ietf-oauth-security-topics-11"
        }}>{`https://tools.ietf.org/html/draft-ietf-oauth-security-topics-11`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://jwt.io/introduction/"
        }}>{`https://jwt.io/introduction/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://auth0.com/docs/api-auth/tutorials/verify-access-token#sample-implementation"
        }}>{`https://auth0.com/docs/api-auth/tutorials/verify-access-token#sample-implementation`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      